var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { ToastMessage, toastSuccess, MessageBar, MessageBarType } from '@samc/react-ui-core';
import { TaskStatus, useApplicationContext } from '@samc/screen-config-api';
import TaskResponsePopup from '@samc/screen-config-core/lib/molecules/TaskResponsePopup/TaskResponsePopup';
import { FormScreenWrapper, GridScreenWrapper, ServiceDrivenViewSet } from '@samc/screen-config-core';
import { useHistoryContext } from '@samc/react-ui-history';
import { apiProvider } from '../../../ReportApi/api/Requests';
import { useApiContext } from '../../../ReportApi/Context/useApiContext';
import { DefaultValidationResponse, getValidationResponse } from '../../../static/ReportUtil';
import { useStandardReport } from '../../../ReportApi/useStandardReport/useStandardReport';
import { ModalPopup } from '../../../atoms/Modal/ModalPopup';
export const CloneReport = ({ refreshViewSet, updateAction, setisLoading, props, isOpen, headerText, onDismiss, onSuccess, }) => {
    var _a, _b, _c, _d;
    const { browserHistory } = useHistoryContext();
    const [reportInfo, setReportInfo] = React.useState();
    const [includeEntitlementInfo, setIncludeEntitlementInfo] = React.useState(false);
    const [validationResponse, setValidationResponse] = React.useState(Object.assign({}, DefaultValidationResponse));
    const { currentUser, Entitlements } = useApplicationContext();
    if (!currentUser.hasEntitlement(Entitlements.ManageReports))
        return (React.createElement("div", null,
            React.createElement(MessageBar, { messageBarType: MessageBarType.error, title: "Error loading Clone Reports", text: "You are not authorized to view this page. Please contact your administrator if you believe this is an error." })));
    const onErrorPopupClose = () => {
        setValidationResponse(Object.assign(Object.assign({}, validationResponse), { messages: [], childResponses: {} }));
    };
    const adhocmembers = [
        {
            scalarDisplayName: 'Description',
            scalarExpression: '[Description]',
        },
        {
            scalarDisplayName: 'IsActive',
            scalarExpression: '[IsActive]',
        },
        {
            scalarDisplayName: 'ReportTemplateStrataId',
            scalarExpression: '[ReportTemplateStrataId]',
        },
        {
            scalarDisplayName: 'ReportImageStrataId',
            scalarExpression: '[ReportImageStrataId]',
        },
        {
            scalarDisplayName: 'DataLevel',
            scalarExpression: '[DataLevel]',
        },
        {
            scalarDisplayName: 'ListViewId',
            scalarExpression: '[ListViewId]',
        },
        {
            scalarDisplayName: 'FilterId',
            scalarExpression: '[FilterId]',
        },
        {
            scalarDisplayName: 'ValidationExpression',
            scalarExpression: '[ValidationExpression]',
        },
        {
            scalarDisplayName: 'ValidationText',
            scalarExpression: '[ValidationText]',
        },
        {
            scalarDisplayName: 'Source',
            scalarExpression: '[Source]',
        },
        {
            scalarDisplayName: 'ReportPeriodStart',
            scalarExpression: '[ReportPeriodStart]',
        },
        {
            scalarDisplayName: 'TargetCurrency',
            scalarExpression: '[TargetCurrency]',
        },
        {
            scalarDisplayName: 'IncludesCurrencyExchange',
            scalarExpression: '[IncludesCurrencyExchange]',
        },
    ];
    const adhocmembersList = ((_a = props.domainId) === null || _a === void 0 ? void 0 : _a.includes('REPORT_StandardReport'))
        ? adhocmembers
        : [
            ...[
                {
                    scalarDisplayName: 'StandardReportId',
                    scalarExpression: '[StandardReportId]',
                },
            ],
            ...adhocmembers,
        ];
    const { data: standardReportData, isLoading: isLoadingParticipants } = useStandardReport(props.primaryKeyValue, props.domainId, ((_b = props.domainId) === null || _b === void 0 ? void 0 : _b.includes('REPORT_StandardReport')) || ((_c = props.domainId) === null || _c === void 0 ? void 0 : _c.includes('REPORT_Report'))
        ? adhocmembersList
        : [], `CloneReport`);
    React.useEffect(() => {
        if (!isLoadingParticipants) {
            const dataInfo = standardReportData === null || standardReportData === void 0 ? void 0 : standardReportData.Data[0];
            setReportInfo({
                Name: dataInfo === null || dataInfo === void 0 ? void 0 : dataInfo.Name,
                Description: dataInfo === null || dataInfo === void 0 ? void 0 : dataInfo.Description,
                IsActive: false,
                IncludeEntitlements: false,
            });
        }
    }, [isLoadingParticipants]);
    const api = useApiContext();
    const onSubmit = React.useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        var _e;
        setisLoading(true);
        const payload = standardReportData === null || standardReportData === void 0 ? void 0 : standardReportData.Data.reduce((p, item, index) => {
            var _a, _b;
            return (Object.assign(Object.assign({}, p), { [`${-index - 1}`]: {
                    __ADDED__: true,
                    Id: -1,
                    StandardReportId: ((_a = props.domainId) === null || _a === void 0 ? void 0 : _a.includes('REPORT_Report')) ? item.StandardReportId : item.Id,
                    Name: reportInfo === null || reportInfo === void 0 ? void 0 : reportInfo.Name,
                    Description: reportInfo === null || reportInfo === void 0 ? void 0 : reportInfo.Description,
                    DomainId: item.DomainId,
                    IsActive: reportInfo === null || reportInfo === void 0 ? void 0 : reportInfo.IsActive,
                    ReportTemplateStrataId: item.ReportTemplateStrataId,
                    ReportImageStrataId: item.ReportImageStrataId,
                    DataLevel: item.DataLevel,
                    ListViewId: item.ListViewId,
                    FilterId: item.FilterId,
                    ValidationExpression: item.ValidationExpression,
                    ValidationText: item.ValidationText,
                    IncludesCurrencyExchange: item.IncludesCurrencyExchange,
                    Source: item.Source,
                    TargetCurrency: item.TargetCurrency,
                    ReportPeriodStart: item.ReportPeriodStart,
                    ClonedFromId: ((_b = props.domainId) === null || _b === void 0 ? void 0 : _b.includes('REPORT_Report')) ? item.Id : null,
                    IncludeEntitlements: reportInfo === null || reportInfo === void 0 ? void 0 : reportInfo.IncludeEntitlements,
                } }));
        }, {});
        apiProvider
            .submitTask('REPORT_Report', 'REPORT_Report', 'Clone Report', payload, (_e = api.requestInit) !== null && _e !== void 0 ? _e : {}, false)
            .then(([response, request]) => {
            if (response.statusCode !== TaskStatus.Completed ||
                response.messageCount > 0 ||
                validationResponse.prompts.length > 0) {
                setValidationResponse(getValidationResponse(request, response));
            }
            else {
                toastSuccess(React.createElement(ToastMessage, { title: "Success", message: "Report cloned successfully." }));
                onSuccess();
                updateAction(undefined);
                refreshViewSet();
                browserHistory.push('/screen/#/screens/REPORT_StandardReport/PortfolioReports');
            }
        })
            .finally(() => {
            setisLoading(false);
        });
    }), [standardReportData, props.domainId, setisLoading, reportInfo]);
    const onChange = React.useCallback((data) => {
        var _a;
        const includeEntitlement = ((_a = props.domainId) === null || _a === void 0 ? void 0 : _a.includes('REPORT_Report'))
            ? data.IncludeEntitlements
            : false;
        setIncludeEntitlementInfo(includeEntitlement);
        setReportInfo({
            Name: data.Name,
            Description: data.Description,
            IsActive: data.IsActive,
            IncludeEntitlements: includeEntitlement,
        });
    }, [props.primaryKeyValue]);
    const { request } = validationResponse;
    const requests = React.useMemo(() => [request], [request]);
    const responses = React.useMemo(() => [validationResponse], [validationResponse]);
    return (React.createElement(React.Fragment, null,
        React.createElement(ModalPopup, { isOpen: isOpen, headerText: headerText, onDismiss: () => {
                onDismiss(false);
            }, onSubmit: onSubmit, confirmationButtonDisable: isLoadingParticipants, rejectionButtonText: "Cancel", confirmationButtonText: "Clone Report" },
            React.createElement(React.Fragment, null,
                React.createElement("div", { style: { margin: '15px 10px 0px 20px' } }, includeEntitlementInfo ? (React.createElement(React.Fragment, null, "The cloned report will appear in the Portfolio Reports tab as inactive. Once the report is set to active, it will be available for users to access in the Report Catalog Screen.")) : (React.createElement(React.Fragment, null, "The cloned report will appear in the Portfolio Reports tab as inactive. Once the report is set to active, it must be entitled (via the Roles and Entitlements screen) before any users can access the report in the Report Catalog screen."))),
                React.createElement(FormScreenWrapper, { formViewId: ((_d = props.domainId) === null || _d === void 0 ? void 0 : _d.includes('REPORT_Report')) ? 'REPORT_ReportDetailsClone' : 'REPORT_StandardReportClone', primaryKeyValue: props.primaryKeyValue, onChange: onChange, GridScreenWrapper: GridScreenWrapper, ServiceDrivenViewSet: ServiceDrivenViewSet }))),
        React.createElement(TaskResponsePopup, { requests: requests, responses: responses, visible: validationResponse.messages.length > 0 ||
                validationResponse.prompts.length > 0 ||
                Object.values(validationResponse.childResponses).some(({ messages }) => messages.length > 0), setVisible: onErrorPopupClose })));
};
export default CloneReport;
